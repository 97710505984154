<template>
  <v-container fluid class="info-art-related px-0">
    <v-row no-gutters>
      <v-col cols="12" class="pb-3">
        <h3>{{ $t('information.related_topics') }}</h3>
      </v-col>
      <v-col cols="12">
        <div
          v-for="topic in relatedTopicsTags"
          :key="`topic-${topic}`"
          class="tag-item"
          @click="updateSearchQueryWithTags(topic)"
        >
          <v-chip
            class="topic-chip"
            :class="{ 'active-item': isActive(topic) }"
            outlined
          >
            {{ topic }}
          </v-chip>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InformationSearchMixins from '@/modules/information/mixins/InformationSearchMixins';

export default {
  name: 'InfoArticleRelatedTopics',
  mixins: [InformationSearchMixins],
  props: {
    relatedTopicsTags: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    isActive(val) {
      if (this.$route.query.tags) {
        let tmpArray = this.$route.query.tags.split(',');

        if (tmpArray.includes(val)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.info-art-related {
  margin: 21px 0 0;
  padding-top: 38px;
  border-top: 1px solid #cfd5e0;

  h3 {
    @include font-size(20, 30, 700);
    color: #0a0e14;
  }
}

.tag-item {
  text-decoration: none;
  display: inline-block;
  margin-bottom: 8px;

  &:not(:last-child) {
    margin-right: 7px;
  }
}

.topic-chip {
  border: 1px solid #cfd5e0;
  color: var(--v-grey8-base);
  @include font-size(12, 140);
  cursor: pointer;

  &.active-item {
    border: 1px solid var(--v-primary-base);

    color: var(--v-primary-base);

    &.v-chip.v-chip--outlined.v-chip.v-chip {
      background: #f2f8ff !important;
    }
  }
}
</style>
