<script>
import InformationMixins from '@/modules/information/mixins/InformationMixins';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import { mapboxService } from '@/core/services/MapboxService';

export default {
  name: 'InformationSearchMixins',
  mixins: [InformationMixins, SelectedContextFlightMixins],

  data() {
    return {
      isLoadingSearching: false,
      errors: [],
    };
  },

  methods: {
    loadArticlesByQuery() {
      let query = this.$route.query;
      let queryString = '';
      for (const queryParam in query) {
        if (queryParam === 'city') {
          queryString += `cities=${query[queryParam]}&`;
        } else {
          queryString += `${queryParam}=${query[queryParam]}&`;
        }
      }
      this.loadArticles(queryString);
    },

    async searchFlightCity(val, oldVal) {
      let city;
      await this.matchesContextCityAndSearchCity(oldVal);
      if (val.destination.city !== oldVal.destination.city) {
        if (!this.$route.query.tags && this.isCityMatches) {
          city = await this.autoSearch();
        } else {
          this.$emit('showAlert');
        }
      }
      return city;
    },

    async autoSearch() {
      let city;
      if (this.selectedContextFlight) {
        city = await this.getSelectedSearch(
          this.selectedContextFlight.destination.city
        );
        this.updateSearchQuery(city);
      }
      return city;
    },

    async getSelectedSearch(city) {
      try {
        this.isLoadingSearching = true;
        const res = await mapboxService.getCities(city);
        if (res.features && res.features.length > 0) {
          return {
            city: res.features[0].text_en || res.features[0].text,
            longLat: res.features[0].center,
            text: res.features[0].place_name_en || res.features[0].place_name,
          };
        }
      } catch (err) {
        this.errors.push(err.response.data.message);
        this.isLoadingSearching = false;
      } finally {
        this.isLoadingSearching = false;
      }
    },

    updateSearchQuery(query) {
      if (this.$route.query.city !== query?.city) {
        let queryObject = {};

        if (this.$route.query.tags) queryObject.tags = this.$route.query.tags;
        if (query && query.city) queryObject.city = query.city;
        if (query && query.longLat.length > 0) {
          queryObject.lng = query.longLat[0];
          queryObject.lat = query.longLat[1];
        }

        this.$router.replace({
          name: this.$route.name,
          params: this.$route.params,
          query: queryObject,
        });

        this.loadArticlesByQuery();
      }
    },

    async checkQuery() {
      try {
        this.isLoadingSearching = true;
        if (this.$route.query && this.$route.query.city) {
          const city = await mapboxService.getCities(this.$route.query.city);
          if (city && city.features.length > 0) {
            return {
              text:
                city.features[0].place_name_en || city.features[0].place_name,
              longLat: city.features[0].center,
              city: city.features[0].text,
            };
          }
        }
      } catch (err) {
        this.errors.push(err.response.data.message);
        this.isLoadingSearching = false;
      } finally {
        this.isLoadingSearching = false;
      }
    },

    updateSearchQueryWithTags(val) {
      let queryString = { ...this.$route.query };

      if (this.$route.query.tags) {
        let tmpArray = this.$route.query.tags.split(',');

        if (tmpArray.includes(val)) {
          queryString.tags = tmpArray.filter((e) => e !== val).join();
        } else {
          queryString.tags = this.$route.query.tags.concat(',', val);
        }
      } else {
        queryString.tags = val;
      }

      if (queryString.tags === '') delete queryString.tags;

      const routeConfig = {
        name: 'information-landing',
        query: queryString,
      };
      this.$router.push(routeConfig).catch((err) => {});
      this.loadArticlesByQuery();
    },
  },
};
</script>
